import { Injectable } from '@angular/core';

import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialGuardService implements CanActivate 
{
  constructor(private router: Router, private auth: AuthService) 
  { }

  private unAuthRoutes = ['tutorial'];

  canActivate(route: ActivatedRouteSnapshot) 
  {    
    if ( this.auth.getLoggedInUser() ) 
    {
      let user = this.auth.getLoggedInUser();

      if( user['tutorialFinished'] == false ||  !user['tutorialFinished'] )
      {
        if (this.unAuthRoutes.indexOf(route.url.toString()) != -1) 
        {
          return true;
        }
        else
        {
          this.router.navigate([`${user.role}/tutorial`]);
          return true;
        }
        
      }
      else if (this.unAuthRoutes.indexOf(route.url.toString()) != -1) 
      {
        this.router.navigate([`${user.role}/home`]);
        return true;
      }
      else return true;
    }
    else
    {
      this.router.navigate([`login`]);
      return true;
    } 
  }
}
